import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Label, Spinner } from 'reactstrap'

import { useAuth } from '../../../providers/AuthProvider'
import { useFirebase } from '../../../providers/FirebaseProvider'
import { usePlatform } from '../../../providers/PlatformProvider'

const ShopifyInstallationModal = () => {
  const { t, i18n } = useTranslation()

  const { firebase } = useFirebase()
  const { toggleInstallationModal, shopifyApiCall } = usePlatform()
  const { userData } = useAuth()

  const [themes, setThemes] = useState([])
  const [currentTheme, setCurrentTheme] = useState()
  const [selectedTheme, setSelectedTheme] = useState()

  const lang = i18n.language ?? 'ja'

  const clicked = async () => {
    userData.platform.shownInstallationInstruction = true
    await firebase.updateUserData({ ...userData })
    toggleInstallationModal()
  }

  const getShopifyThemes = async () => {
    try {
      const { themes } = await shopifyApiCall('getInstalledThemes')
      const main = themes.find((theme) => theme.role === 'main')
      setThemes(themes)
      setCurrentTheme(main.id)
      setSelectedTheme(main.id)
    } catch (e) {
      console.log('getShopifyThemes-error', e)
    }
  }

  const changed = async (e) => {
    setSelectedTheme(e.target.value)
  }

  //Shopify
  useEffect(() => {
    getShopifyThemes()
  }, [])

  return (
    <div
      className="d-flex align-center flex-column justify-content-center"
      style={{ minHeight: '200px' }}
    >
      <h3 className="text-center mb-3">{t('Promolayer Installation')}</h3>
      <ol>
        <li style={{ marginBottom: '5px' }}>
          {t('Open your theme settings by clicking "Enable Promolayer" below.')}
        </li>
        <li style={{ marginBottom: '5px' }}>
          {t('Ensure the "Promolayer" switch is on (blue).')}
        </li>
        <img
          style={{
            width: '100%',
            maxWidth: '300px',
            display: 'block',
            margin: '10px auto 15px auto'
          }}
          src={`/assets/images/shopify/install-1-${lang}.png`}
        />
        <li>
          <u>{t('Press the "save" button.')}</u>
        </li>
        <img
          style={{
            width: '100%',
            maxWidth: '300px',
            textAlign: 'center',
            display: 'block',
            margin: '10px auto'
          }}
          src={`/assets/images/shopify/install-2-${lang}.png`}
        />
      </ol>
      <div className="mt-4">
        {themes.length && currentTheme ? (
          <>
            <Label className="d-none">{t('Select theme')}</Label>
            <Input
              className="d-none"
              value={selectedTheme}
              type="select"
              onChange={changed}
            >
              {themes.map((theme) => (
                <option key={theme.id} value={theme.id}>
                  {theme.name} {currentTheme === theme.id ? t('(Current)') : ''}
                </option>
              ))}
            </Input>
            <div className="mt-2 text-center">
              <a
                href={`https://${userData.userId}/admin/themes/${selectedTheme}/editor?context=apps&appEmbed=${process.env.NEXT_PUBLIC_SHOPIFY_EXTENSION_UUID}%2Fapp-embed`}
                target="_blank"
              >
                <Button color="primary" onClick={clicked}>
                  {t('Enable Promolayer')}{' '}
                  <i className="fas fa-external-link-alt ms-3"></i>
                </Button>
              </a>
            </div>
          </>
        ) : (
          <div className="pt-3 text-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}

export default ShopifyInstallationModal
